export const formatPrice = number => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
  }).format(number / 100)
}

export const formatPriceEur = number => {
  return new Intl.NumberFormat('en-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(number / 100)
}

export const formatPriceAed = number => {
  return new Intl.NumberFormat('ar-AE', {
    style: 'currency',
    currency: 'AED',
  }).format(number / 100)
}

export const getUniqueValues = (data, type) => {
  let unique = data.map(item => item[type])
  if (type === 'colors') {
    unique = unique.flat()
  }

  return ['all', ...new Set(unique)]
}
