import React from "react";
import styled from "styled-components";
import { BsInstagram, BsTiktok, BsTwitter, BsFacebook } from "react-icons/bs";

const Footer = () => {
  return (
    <Container>
      <div className="social-icons">
        <a href="https://www.instagram.com/bagai.dubai/" target="_blank">
          <BsInstagram color="#fff" size={24} className="icon" />
        </a>
      </div>
      <h5>
        &copy; {new Date().getFullYear()}
        <span> Bagai Dubai</span> - All Rights Reserved.
      </h5>
      <a href="">
        <h6>
          Made by <span>Nicolò Cardozo</span>
        </h6>
      </a>
    </Container>
  );
};

const Container = styled.footer`
  height: 9rem;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--clr-black);
  text-align: center;

  span {
    color: #a7ede7;
    text-transform: uppercase;
  }

  h5 {
    color: var(--clr-white);
    margin: 0.1rem;
    font-weight: 300;
    text-transform: none;
    line-height: 1.25;
  }

  h6 {
    margin-top: 1rem;
    color: var(--clr-white);
    font-weight: 400;

    span {
      text-transform: capitalize;
    }
  }

  .social-icons {
    padding: 1.75rem 0 0.75rem;

    svg {
      margin: 0 0.5rem;
    }

    path:hover {
      color: #a7ede7;
    }
  }

  @media (min-width: 776px) {
    //flex-direction: row;
  }
`;

export default Footer;
