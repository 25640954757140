import React from "react";
import { useProductsContext } from "../context/products_context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Error from "./Error";
import Loading from "./Loading";
import Product from "./Product";
import aboutImg from "../assets/about-background.jpg";

const FeaturedProducts = () => {
  const {
    products_loading: loading,
    products_error: error,
    featured_products: featured,
  } = useProductsContext();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Wrapper className="section">
      <div className="title">
        <h2 className="underline-hover-effect">latest arrivals</h2>
      </div>
      <div className="section-center featured ">
        {featured.slice(0, 3).map((product) => {
          return <Product key={product.id} {...product} />;
        })}
      </div>
      <Link to="/products" className="btn">
        all watches
      </Link>
      <div className="section-center-about">
        <div className="services-center">
          <article className="service">
            <span className="icon"></span>
            <h4>about us</h4>
            <p>
              Discover the epitome of luxury in our exquisite collection of fine
              timepieces. We pride ourselves on curating an exceptional
              assortment of pre-owned luxury watches, showcasing the pinnacle of
              craftsmanship and sophistication. Immerse yourself in a world
              where elegance meets precision. Our carefully selected inventory
              features prestigious brands and iconic models that embody timeless
              style. Each watch has been meticulously inspected and
              authenticated by our experts, ensuring uncompromising quality and
              reliability. At our marketplace, we understand the discerning
              tastes of watch enthusiasts like you. Whether you seek a vintage
              masterpiece or a contemporary statement piece, our collection
              caters to every horological desire. Our dedicated team of experts
              is here to provide personalized assistance, guiding you towards
              the perfect timepiece that resonates with your individual style.
              Experience the allure of owning a luxury watch without compromise.
              With our unparalleled selection and unwavering commitment to
              excellence, we invite you to explore the extraordinary world of
              fine timepieces and embark on a journey of refined elegance..
            </p>
          </article>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: var(--clr-black);
  padding: 5rem 0 0;

  .services-center {
    margin-top: 4rem;
    display: grid;
    gap: 3rem;
  }

  .service {
    //background: #defffd;
    // background: #fff;
    text-align: center;
    padding: 2rem 3.5rem;
    border-radius: var(--radius);
    box-shadow: rgba(0, 0, 0, 0.64) 0px 0px 0.175rem,
      rgba(0, 0, 0, 0.6) 0px 0px 1.125rem;
    background: url(${aboutImg}) center/cover no-repeat;

    p {
      color: #404040;
    }
  }

  h2 {
    color: #e5e5e5;
  }

  h4 {
    color: #171717;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .featured {
    margin: 5rem auto;
    display: grid;
    gap: 2.5rem;

    img {
      height: 350px;
    }
  }

  .btn {
    display: block;
    width: 200px;
    padding: 0.75rem 1.5rem;
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    border: 1px solid var(--clr-black);
  }

  .btn:hover {
    box-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
    background: #a7ede7;
    color: var(--color-black);
  }

  .btn:active {
    box-shadow: 0 0 0.175rem #000000a3, 0 0 1.125rem #0009;
  }

  @media (min-width: 576px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }

    .services-center {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }

  @media (min-width: 1280px) {
    .section-center-about {
      transform: translateY(11rem);
      width: 55vw;
      margin: 0 auto;
      max-width: var(--max-width);
      margin-top: -8.5rem;
    }
  }

  @media (max-width: 1280px) {
    .service {
      border-radius: 0;
      box-shadow: none;
      background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.8)
        ),
        url(${aboutImg}) center/cover no-repeat;
    }
  }

  @media (max-width: 480px) {
    .section {
      padding: 2rem 0 0;
    }
  }
`;

export default FeaturedProducts;
