import React from 'react'
const Error = () => {
  return (
    <div className="section section-center text-center">
      <h2>there was an error...</h2>
    </div>
  )
}

export default Error
