import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import heroImg from "../assets/hero.jpg";
import logo from "../assets/bagai-logo.svg";
import { BsChevronDoubleDown } from "react-icons/bs";

const Hero = () => {
  return (
    <Wrapper>
      <div className="hero">
        <div className="section-center">
          <article className="content">
            <img src={logo} alt="bagai" className="" />
            <div>
              <p>
                Elevate your wrist game: handpicked selection of timepieces,
                discover unrivaled elegance and impeccable accuracy in every
                tick.
              </p>
            </div>

            <div className="btn-container">
              <Link to="/products" className="btn hero-btn">
                our watches
              </Link>
            </div>
          </article>
        </div>
        <div className="arrow-icon">
          <BsChevronDoubleDown
            size={24}
            color="black"
            style={{
              textShadowColor: "red",
              shadowOpacity: 1,
              shadowRadius: 5,
              textShadowOffset: { width: 5, height: 2 },
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 60vh;
  display: grid;
  place-items: center;
  background: url(${heroImg}) center/cover no-repeat;
  background-attachment: absolute;
  background-position: center top;

  img {
    padding: 0 1rem;
    width: auto;
    margin: 12.5rem 0 3rem;
  }

  .arrow-icon {
    display: none;
  }

  .img-container {
    display: none;
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 4rem;
    color: var(--clr-grey-5);
    font-size: 1rem;
    padding: 0 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  h1 {
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-size: 5rem;
    text-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
    color: #fff;
    letter-spacing: -0.3rem;
  }

  .hero-btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: var(--clr-white);
    background: black;
  }

  @media (min-width: 992px) {
    height: 100vh;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;

    img {
      width: 100%;
      margin: 4rem 0 3rem;
      padding: 0;
    }

    h1 {
      margin-bottom: 2rem;
      text-transform: capitalize;
      font-size: 7rem;
      text-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
      color: #fff;
      letter-spacing: -0.3rem;
    }

    p {
      font-size: 1.25rem;
      text-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
      color: #fff;
      font-weight: 500;
      /* margin-left: 0.8rem; */
      padding: 0;
    }

    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      border: 1px solid var(--clr-black);
    }

    .btn:hover {
      box-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
    }

    .btn:active {
      box-shadow: 0 0 0.175rem #000000a3, 0 0 1.125rem #0009;
    }

    .content {
      padding: 0 20rem;
      align-items: left;
    }
  }

  @media (min-width: 481px) and (max-width: 992px) {
    p {
      font-size: 1.25rem;
      text-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
      color: #fff;
      font-weight: 500;
      margin-left: 0.8rem;
      text-align: left;
    }

    .content {
      justify-content: center;
      align-items: center;
    }

    h1 {
      line-height: 0.95;
    }

    .btn:active {
      box-shadow: 0 0 0.175rem #000000a3, 0 0 1.125rem #0009;
    }
  }

  @media (max-width: 480px) {
    @keyframes bounce {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }

    .section {
      padding: 2rem 0 0;
    }

    h1 {
      font-size: 4rem;
      margin: 11rem 0 2rem;
      text-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
    }

    p {
      font-size: 1rem;
      text-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
      color: #fff;
      font-weight: 500;
      text-align: center;
      margin: -1rem 0 4rem;
    }

    .hero {
      height: 100vh;
    }

    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      border: 1px solid var(--clr-black);
    }

    .btn:hover {
      box-shadow: 0 0 0.375rem #000000a3, 0 0 3.125rem #0009;
    }

    .btn:active {
      box-shadow: 0 0 0.175rem #000000a3, 0 0 1.125rem #0009;
    }

    .arrow-icon {
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      // margin-top: 30%;
      bottom: 80%;
      animation: bounce 2s ease infinite;
    }

    .content {
      align-items: center;
    }
  }

  @media (max-height: 740px) {
    .arrow-icon {
      display: none;
    }
  }
`;

export default Hero;
