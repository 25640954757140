import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProductsContext } from "../context/products_context";
import { single_product_url as url } from "../utils/constants";
import { formatPrice, formatPriceEur, formatPriceAed } from "../utils/helpers";
import { Loading, Error, ProductImages } from "../components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";

const SingleProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  console.log(id);

  const {
    single_product_loading: loading,
    single_product_error: error,
    single_product: product,
    fetchSingleProduct,
  } = useProductsContext();

  useEffect(() => {
    console.log(url);
    console.log(id);
    fetchSingleProduct(`${url}${id}`);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    // eslint-disable-next-line
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  const {
    name,
    price_usd,
    price_eur,
    price_aed,
    description,
    stock,
    reference,
    company,
    images,
  } = product;
  return (
    <Wrapper>
      <div className="section section-center page">
        <Link to="/products" className="">
          <div className="back-icon">
            <BiArrowBack size={20} />
          </div>
        </Link>

        <div className="product-center">
          <ProductImages images={images} />
          <section className="content">
            <h2>{name}</h2>
            <div className="prices">
              <span className="price">{formatPrice(price_usd)}</span>
              <span className="price">{formatPriceEur(price_eur)}</span>
              <span className="price">{formatPriceAed(price_aed)}</span>
            </div>

            <p className="desc">{description}</p>
            <p className="info">
              <span>Available:</span>
              {stock > 0 ? (
                <>
                  In stock <AiFillCheckCircle size={20} color="green" />
                </>
              ) : (
                <>
                  out of stock <AiFillCloseCircle size={20} color="red" />
                </>
              )}
            </p>
            <p className="info">
              <span>Brand:</span>
              {company}
            </p>
            <p className="info last-info">
              <span>REF NUM:</span>
              {reference}
            </p>
            <hr />

            {stock > 0 && (
              <Link
                to={`//api.whatsapp.com/send?phone=971507624729&text=Hello%2C+I%27m+interested+in+this+watch%3A+${name}.+Is+possible+to+book+an+appointment%3F+Thank+you.`}
                className="btn"
                target="_blank"
              >
                <BsWhatsapp size={24} className="wa-icon" />
                &nbsp;&nbsp;Chat on WhatsApp
              </Link>
            )}
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  padding-top: 5rem;

  .page {
    min-height: calc(100vh - 14rem);
  }

  p {
    margin-bottom: 0.75rem;
    color: #404040;
  }

  .last-info {
    margin-bottom: 2rem;
  }

  h2,
  span {
    color: var(--clr-black);
  }

  h5 {
    margin-bottom: 2rem;
    color: var(--clr-black) !important;
    letter-spacing: 0;
  }

  a {
    display: inline-block;
  }

  .back-icon {
    color: var(--clr-white);
    background: var(--clr-black);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .back-icon:hover {
    color: var(--clr-black);
    background: #a7ede7;
    box-shadow: 0 0 0.175rem #000000a3, 0 0 1.125rem #0009;
  }

  .back-icon:active {
    box-shadow: 0 0 0.09rem #000000a3, 0 0 0.565rem #0009;
  }

  .product-center {
    display: grid;
    gap: 4rem;
    margin-top: 2rem;
  }

  .price {
    color: var(--clr-black);
    font-weight: 600;
    letter-spacing: 0;
    padding: 0.1rem 1rem;
    margin-right: 1rem;
    background: #a7ede7;
    border-radius: 10px;
    text-align: center;
    // font-size: 1.1rem !important;

    @media (max-width: 500px) {
      margin-right: 0;
    }
  }

  .prices {
    display: flex;
    justify-content: left; /* Align prices to the left */
    margin: 1.5rem 0;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; /* Center the prices on smaller screens */
      gap: 0.5rem;

      span {
        width: 100%; /* Each price spans the full width on smaller screens */
      }
    }
  }

  .desc {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
  }

  .info {
    text-transform: capitalize;
    width: 300px;
    display: grid;
    grid-template-columns: 100px fit-content(170px) 1fr;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 700;
    }

    svg {
      margin: 0 auto;
    }
  }

  .btn-container {
    p {
      margin: 1rem 0 1rem;
    }
  }

  .btn {
    margin-top: 2rem;
    width: 220px;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #25d367;
    color: white;
    text-transform: none;
    font-size: 1rem;
    border-radius: 8px;

    &:hover {
      box-shadow: 0 0 10px 2px #25d367;
    }

    &:active {
      box-shadow: 0 0 5px 1px #25d367;
    }
  }

  @media (min-width: 992px) {
    .product-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      margin-top: 4rem;
    }

    .price {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 480px) {
    .section {
      padding: 1rem 0 2rem;
    }
  }
`;

export default SingleProductPage;
