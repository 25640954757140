import React from "react";
import styled from "styled-components";
import { BsWhatsapp, BsInstagram, BsPinMapFill } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";

const ContactsPage = () => {
  return (
    <main>
      <Wrapper className="page section section-center">
        <div className="flex-container">
          <div className="location">
            <BsPinMapFill size={24} color="black" />
            <p>PO Box 12345, Dubai</p>
          </div>
          <div className="whatsapp">
            <BsWhatsapp size={24} color="black" />
            <p>+971 50 762 4729</p>
          </div>
          <div className="email">
            <MdAlternateEmail size={26} color="black" />
            <p>info@bagaidubai.com</p>
          </div>
          <div className="instagram">
            <BsInstagram size={24} color="black" />
            <p>@bagai.dubai</p>
          </div>
        </div>

        <div className="container">
          <h2>Contact Us</h2>
          <h5>
            If you don't find the watch you're looking for and want our help, or
            you just need any info, text us!
          </h5>
          <form
            action="https://formspree.io/f/moqoqlgq"
            method="POST"
            id="contact_form"
          >
            <div className="name">
              <label htmlFor="name"></label>
              <input
                type="text"
                placeholder="Name"
                name="name"
                id="name_input"
                required
              />
            </div>
            <div className="email-form">
              <label htmlFor="email"></label>
              <input
                type="email"
                placeholder="Email"
                name="email"
                id="email_input"
                required
              />
            </div>
            <div className="telephone">
              <label htmlFor="name"></label>
              <input
                type="text"
                placeholder="Phone Number"
                name="telephone"
                id="telephone_input"
                required
              />
            </div>

            <div className="message">
              <label htmlFor="message"></label>
              <textarea
                name="message"
                placeholder="How can we help you?"
                id="message_input"
                cols="30"
                rows="5"
                required
              ></textarea>
            </div>
            <div className="submit">
              <input type="submit" value="Send Message" id="form_button" />
            </div>
          </form>
        </div>
      </Wrapper>
    </main>
  );
};
const Wrapper = styled.section`
  display: grid;
  gap: 4rem;

  .flex-container {
    margin-top: 4rem;
    display: grid;
    gap: 2.5rem;
  }

  .location,
  .whatsapp,
  .email,
  .instagram {
    text-align: center;
    padding: 0 2rem;
    padding: 0.75rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    transition: var(--transition);
  }

  .location:hover,
  .whatsapp:hover,
  .email:hover,
  .instagram:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
  }

  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  }

  h2,
  h5 {
    margin: 0 auto;
    color: var(--clr-black);
  }

  h2 {
    margin-bottom: 0.75rem;
    text-transform: uppercase;
  }

  h5 {
    text-transform: none;
    letter-spacing: 1px;
  }

  p {
    max-width: 45em;
    margin: 0 auto;
    margin-top: 0.75rem;
    color: var(--clr-black);
  }

  .title {
    text-align: left;
  }

  .underline {
    margin-left: 0;
  }

  @media (max-width: 1136px) {
    .container {
      margin-top: 5rem;
    }
  }

  @media (max-width: 577px) {
    .container {
      margin-top: 0;
    }

    padding-top: 1rem;
  }

  @media (max-width: 992px) {
    form {
      display: grid;
      gap: 1rem;
    }

    .name,
    .email-form,
    .telephone,
    .message {
      width: 100% !important;
    }
  }

  @media (min-width: 578px) {
    .flex-container {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      height: 100px;
    }
  }

  .container {
    display: grid;
    padding: 2rem 3rem 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .text {
      display: grid;
      justify-content: center;
      margin: 0;
    }

    p {
      margin: 0 auto;
    }
  }

  form {
    padding: 37.5px;
    margin: 50px 0;
  }

  h1 {
    color: black;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 7px;
    text-align: center;
    text-transform: uppercase;
  }

  .underline {
    border-bottom: solid 1px black;
    margin: -0.512em auto;
    width: 80px;
  }

  .icon_wrapper {
    margin: 50px auto 0;
    width: 100%;
  }

  .icon {
    display: block;
    fill: black;
    height: 50px;
    margin: 0 auto;
    width: 50px;
  }

  .email-form {
    float: right;
    width: 45%;
  }

  input[type="text"],
  [type="email"],
  select,
  textarea {
    background: none;
    border: none;
    border-bottom: solid 2px black;
    color: black;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0em 0 1.875em 0;
    padding: 0 0 0.875em 0;
    text-transform: uppercase;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-family: "Lato", sans-serif;
  }

  input[type="text"]:focus,
  [type="email"]:focus,
  textarea:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }

  .message {
    float: none;
  }

  .name {
    float: left;
    width: 45%;
  }

  .subject {
    width: 100%;
  }

  .telephone {
    width: 100%;
  }

  textarea {
    line-height: 150%;
    height: 100px;
    resize: none;
    width: 100%;
  }

  #form_button {
    background: none;
    border: solid 1px var(--clr-black);
    color: var(--clr-black);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    outline: none;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: var(--radius);
  }

  #form_button:hover {
    background: var(--clr-black);
    color: var(--clr-white);
  }

  @media screen and (max-width: 768px) {
    #container {
      margin: 20px auto;
      width: 95%;
    }
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 26px;
    }

    .underline {
      width: 68px;
    }

    #form_button {
      padding: 15px 25px;
    }
  }

  @media screen and (max-width: 420px) {
    h1 {
      font-size: 18px;
    }

    .icon {
      height: 35px;
      width: 35px;
    }

    .underline {
      width: 53px;
    }

    input[type="text"],
    [type="email"],
    select,
    textarea {
      font-size: 0.875em;
    }

    .flex-container {
      margin-top: 6rem;
    }
  }
`;
export default ContactsPage;
