import React from 'react'

export const links = [
  {
    id: 1,
    text: 'home',
    url: '/',
  },
  {
    id: 2,
    text: 'products',
    url: '/products',
  },
  {
    id: 3,
    text: 'contacts',
    url: '/contacts',
  },
]

export const products_url = '/.netlify/functions/products'
export const single_product_url = `/.netlify/functions/single-product?id=`
// export const products_url = 'https://course-api.com/react-store-products'
// export const single_product_url = `https://course-api.com/react-store-single-product?id=`
