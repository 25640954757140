import React from 'react'
import styled from 'styled-components'
import { Filters, ProductList, Sort, PageHero } from '../components'

const ProductsPage = () => {
  return (
    <main>
      <Wrapper className="page">
        <div className="section-center products">
          <Filters />
          <div>
            <Sort />
            <div className="product-list">
              <ProductList />
            </div>
          </div>
        </div>
      </Wrapper>
    </main>
  )
}

const Wrapper = styled.div`
  padding-top: 5rem;

  .products {
    display: grid;
    gap: 3rem 1.5rem;
    margin: 4rem auto;
  }

  .product-list {
    h5 {
      color: var(--clr-black);
    }

    p {
      color: var(--clr-black);
      font-weight: 600;
      background: #a7ede7;
      padding: 0.1rem 0.5rem;
      border-radius: 10px;
      letter-spacing: 0;
    }
  }

  @media (min-width: 768px) {
    .products {
      grid-template-columns: 200px 1fr;
    }
  }

  @media (max-width: 480px) {
    .products {
      margin: 1rem auto 3rem;
    }
  }
`

export default ProductsPage
