import React from "react";
import styled from "styled-components";
import logo from "../assets/bagai-symbol.svg";
import { VscThreeBars } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { links } from "../utils/constants";

import { useProductsContext } from "../context/products_context";

const Nav = () => {
  const { openSidebar } = useProductsContext();

  return (
    <NavContainer>
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} alt="bagai" />
          </Link>
          <button type="button" className="nav-toggle" onClick={openSidebar}>
            <VscThreeBars />
          </button>
        </div>
        <ul className="nav-links">
          {links.map((link) => {
            const { id, text, url } = link;
            return (
              <li key={id}>
                <Link to={url}>{text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  h2 {
    color: black;
    font-weight: 900;
    margin-bottom: 0;
    font-family: "Rubik Moonrocks", cursive;
    font-size: 2.75rem;
  }

  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-black);

    cursor: pointer;

    svg {
      font-size: 2rem;
    }
  }

  .nav-links {
    display: none;
  }

  .cart-btn-wrapper {
    display: none;
  }

  img {
    width: 1.25rem;
  }

  @media (min-width: 992px) {
    h2 {
      font-size: 3rem;
    }

    img {
      width: 1.5rem;
      margin-left: -15px;
    }

    .nav-toggle {
      display: none;
    }

    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }

    .nav-links {
      display: flex;
      justify-content: right;

      li {
        margin: 0 0.5rem;
        position: relative;
        padding: 0 0.2rem;
        height: 100%;
        display: flex;
      }

      li::before {
        content: "";
        position: absolute;
        height: 33.33%;
        width: 0;
        background-color: #a7ede7;
        right: 0;
        z-index: 0;
        top: 33.33%;
        transition: all 0.5s;
      }

      li::after {
        content: "";
        position: absolute;
        height: 33.33%;
        width: 0;
        background-color: #a7ede7;
        left: 0;
        z-index: 0;
        bottom: 0;
        transition: all 0.5s;

        a {
          color: #fff;
        }
      }

      li:hover:before,
      li:hover::after,
      li:hover a::before {
        width: 100%;
      }

      a {
        color: var(--clr-black);
        font-size: 1rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        z-index: 1;

        /* &:hover {
          color: #fff;
        } */
      }

      a::before {
        position: absolute;
        content: "";
        height: 33.33%;
        width: 0;
        background-color: #a7ede7;
        bottom: 66.66%;
        left: 0;
        transition: all 0.5s;
      }

      a::after {
        color: #fff;
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`;

export default Nav;
