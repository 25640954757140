import React from 'react'
import styled from 'styled-components'

const Contact = () => {
  return (
    <Wrapper>
      <div className="section-center">
        <h3>Join our newsletter!</h3>
        <div className="content">
          <p>
            Want to stay up to date on our new watches? Sign up for our newsletter, you won't miss any new arrivals!
          </p>
          <form className="contact-form">
            <input type="email" className="form-input" placeholder="enter email" />
            <button type="submit" className="submit-btn">
              subscribe
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
  padding: 5rem 0;

  h3 {
    text-transform: none;
    color: #171717;
  }

  p {
    line-height: 2;
    max-width: 45em;
    color: #404040;
  }

  .contact-form {
    width: 90vw;
    max-width: 500px;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .form-input,
  .submit-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid var(--clr-black);
  }

  .form-input {
    border-right: none;
    color: var(--clr-grey-3);
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
  }

  .form-input:focus {
    outline: none;
  }

  .submit-btn {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }

  .form-input::placeholder {
    color: #d4d4d4;
    text-transform: capitalize;
  }

  .submit-btn {
    background: var(--clr-black);
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    cursor: pointer;
    transition: var(--transition);
    color: var(--clr-white);
  }

  .submit-btn:hover {
    background: #a7ede7;
    color: var(--clr-black);
  }

  @media (min-width: 992px) {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 8rem;
      margin-top: 2rem;
    }
    p {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1280px) {
    padding: 20rem 0 10rem 0;
  }
`

export default Contact
